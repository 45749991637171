<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section class="tabs-section">
		<b-tabs
			:vertical="!['xs', 'sm'].includes(windowWidth)"
			class="mx-auto"
			style="max-width: 85%"
			@input="currentTab = $event">
			<!-- Benefits -->
			<b-tab>
				<template #title>
					<div
						:class="{
							'tab-active': Object.keys(tabs).findIndex((keyName) => keyName === 'benefits') === currentTab,
						}"
						class="tab-title-block">
						<div class="tab-title-container">
							{{ translate('benefits') }}
						</div>
					</div>
				</template>
				<div class="py-5 px-3">
					<div class="row">
						<div class="col-12 col-lg-5">
							<img
								class="w-100"
								:src="tabs.benefits.image">
						</div>
						<div class="col-12 col-lg-7 content">
							<div v-html="tabs.benefits.content" />
						</div>
					</div>
				</div>
			</b-tab>
			<!-- Supplement Facts -->
			<b-tab>
				<template #title>
					<div
						:class="{
							'tab-active': Object.keys(tabs).findIndex((keyName) => keyName === 'supplementsFacts') === currentTab,
						}"
						class="tab-title-block">
						<div class="tab-title-container">
							{{ translate('supplements_facts') }}
						</div>
					</div>
				</template>
				<div class="py-5 px-3">
					<div class="row">
						<div class="col-12 col-lg-5">
							<object
								type="image/svg+xml"
								:data="tabs.supplementsFacts.images.supplementsFacts"
								width="100%">
								Your browser does not support SVG.
							</object>
						</div>
						<div class="col-12 col-lg-7">
							<div class="row">
								<div class="col">
									<div v-html="tabs.supplementsFacts.content" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-tab>
			<!-- FAQs -->
			<b-tab>
				<template #title>
					<div
						:class="{
							'tab-active': Object.keys(tabs).findIndex((keyName) => keyName === 'faqs') === currentTab,
						}"
						class="tab-title-block">
						<div class="tab-title-container">
							{{ translate('faqs') }}
						</div>
					</div>
				</template>
				<div
					:class="['xs','sm'].includes(windowWidth) ? 'p-1' : 'py-5 px-3'"
					class="faq-accordion">
					<b-card
						v-for="(faq, index) in tabs.faqs"
						:key="`faq-${index}-${id}`"
						:ref="`faq-${index}-${id}`"
						no-body
						class="bg-white m-0">
						<b-card-header
							header-tag="header"
							class="p-1"
							role="tab">
							<b-button
								v-b-toggle="`faq-${index}-${id}`"
								block
								class="text-left font-weight-bold"
								href="#"
								variant="default">
								<div
									class="d-inline"
									v-html="faq.q" />
								<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
								<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
							</b-button>
						</b-card-header>
						<b-collapse
							:id="`faq-${index}-${id}`"
							visible
							accordion="faq-accordion"
							role="tabpanel">
							<b-card-body>
								<b-card-text v-html="faq.a" />
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-tab>
		</b-tabs>
		<div
			v-if="disclaimer.length > 0"
			class="d-flex justify-content-center"
			style="width: 100%">
			<div
				v-if="!['xs', 'sm'].includes(windowWidth)"
				style="width: 7.5%" />
			<div
				class="d-flex"
				style="width: 85%">
				<div class="pt-4">
					<div
						class="text-center"
						:style="`color: #7C7C7C;`">
						<div
							class="p-4 mx-auto text-medium"
							style="background-color: #f5f5f5">
							<sup>*</sup> <span v-html="disclaimer" />
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="!['xs', 'sm'].includes(windowWidth)"
				style="width: 7.5%" />
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import PageSection from '../PageSection';

export default {
	name: 'HorizontalTabs',
	messages: [LandingPages],
	components: { PageSection },
	mixins: [WindowSizes],
	props: {
		tabs: {
			type: Object,
			required: true,
			//
		},
		disclaimer: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			// eslint-disable-next-line no-underscore-dangle
			id: this._uid,
			currentTab: 0,
		};
	},
};
</script>
<style lang="scss" scoped>
$borderColor: #ddd !important;
$borderWidth: 1px;
$tabHeight: 65px;
$tabWidth: 280px;
$borderRadius: 4px;

.tabs-section::v-deep {
	.tabs.row {
		margin: 0;

		.col-auto {
			padding: 0;
		}
	}
	.nav .col-auto {
		display: block;
		margin-right: -$borderWidth;
	}
	ul.nav-tabs {
		// Tab list in column (desktop)
		&.flex-column {
			> :first-child .tab-title-block {
				border-radius: $borderRadius 0 0 0 !important;
			}
			.nav-link {
				margin-right: -$borderWidth;
			}
			.tab-title-block {
				border-width: $borderWidth 0 $borderWidth $borderWidth !important;
				min-height: $tabHeight;
				padding-left: 1.5rem;
				padding-right: 1.5rem;
				width: $tabWidth;

				&.tab-active {
					position: relative;
					width: $tabWidth + ($borderWidth);
					z-index: 1;

					.tab-title-container {
						color: #131313 !important;
						border-right: $borderWidth solid white !important;
						font-family: MontserratExtraBold, Montserrat, sans-serif;
						position: absolute;
						height: 100%;
					}
				}
				&:not(.tab-active) {
					background-image: linear-gradient(to right,#fff 70%,rgba(239, 239, 239, 0.5) 100%);
					color: #858585 !important;
				}
			}
		}
		// Tab list in row (mobile)
		&:not(.flex-column) {
			flex-wrap: nowrap;
			white-space: nowrap;
			min-height: 101%;
			overflow: auto hidden;

			&:first-child .tab-title-block {
				border-top-left-radius: 4px;
			}
			&:last-child .tab-title-block {
				border-top-right-radius: 4px;
			}
		}
		.tab-title-block {
			background-color: white;
			border-color: $borderColor;
			border-style: solid;
			border-width: $borderWidth !important;
			min-height: 2.5rem;
			padding-left: 1rem;
			padding-right: 1rem;
			display: flex;
			align-items: center;

			&.tab-active {
				.tab-title-container {
					display: flex;
					align-items: center;
					color: #131313 !important;
					font-family: MontserratExtraBold, Montserrat, sans-serif;
				}
			}
			&:not(.tab-active) {
				color: #858585 !important;
			}
		}
	}
	.tab-content {
		border-color: $borderColor white $borderColor $borderColor;
		border-radius: 0 $borderRadius $borderRadius 0 !important;
		margin: 0;
	}
	.content p {
		color: #4d4d4d;
	}
	.ingredients {
		line-height: 22px;
	}
	.text-small {
		font-size: 10px;
		line-height: 14px;
	}
	.addendum {
		font-size: 10px;
		border: solid 1px #666666;
		padding: 10px;

		p {
			line-height: 14px;
			margin: 0;
		}
	}
	.faq-accordion {
		a {
			color: #131313;

			&:hover {
				color: var(--product-primary-color);
			}
		}
		.when-closed, .when-opened {
			float: right;
		}
		header {
			background-color: white;
		}
		.card {
			&:first-child {
				border-radius: 3px 3px 0 0;
				border-bottom: none;
			}
			&:last-child {
				border: none;

				.card-header, .collapse {
					border-radius: 0 0 3px 3px;
					border-left: 1px solid #c8ced3;
					border-right: 1px solid #c8ced3;
					border-bottom: 1px solid #c8ced3;
				}
			}
			&:not(:first-child) {
				border-radius: 0;
				border-top: none;
				border-bottom: none;
			}
		}
		.collapse {
			border-bottom: 1px solid #c8ced3;
		}
	}
}
</style>
