<!-- eslint-disable vue/no-v-html -->
<template>
	<page-section class="section">
		<div
			:class="{
				'p-1': ['xs', 'sm'].includes(windowWidth),
				'py-5 px-3': !['xs', 'sm'].includes(windowWidth),
				[`faq-accordion-${id}`]: true,
				'accordion-style': true,
			}">
			<b-card
				v-for="(item, index) in items"
				:key="`faq-${index}-${id}`"
				:ref="`faq-${index}-${id}`"
				no-body
				class="bg-white m-0">
				<b-card-header
					header-tag="header"
					class="p-1"
					role="tab">
					<b-button
						v-b-toggle="`faq-${index}-${id}`"
						block
						class="text-left"
						href="#"
						variant="default">
						<div
							class="d-inline h5"
							v-html="item.title" />
						<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
						<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
					</b-button>
				</b-card-header>
				<b-collapse
					:id="`faq-${index}-${id}`"
					visible
					:accordion="`faq-accordion-${id}`"
					role="tabpanel">
					<b-card-body>
						<b-card-text v-html="item.content" />
					</b-card-body>
				</b-collapse>
			</b-card>
		</div>
	</page-section>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { LandingPages } from '@/translations';
import PageSection from '../PageSection';

export default {
	name: 'Accordion',
	messages: [LandingPages],
	components: { PageSection },
	mixins: [WindowSizes],
	props: {
		items: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			// eslint-disable-next-line no-underscore-dangle
			id: this._uid,
			currentTab: 0,
		};
	},
};
</script>
<style lang="scss" scoped>
$borderWidth: 1px;

.section::v-deep {
	.tabs.row {
		margin: 0;

		.col-auto {
			padding: 0;
		}
	}
	.nav .col-auto {
		display: block;
		margin-right: -$borderWidth;
	}
	.content p {
		color: #4d4d4d;
	}
	.text-small {
		font-size: 10px;
		line-height: 14px;
	}
	.addendum {
		font-size: 10px;
		border: solid 1px #666666;
		padding: 10px;

		p {
			line-height: 14px;
			margin: 0;
		}
	}
	.accordion-style {
		max-width: 90%;
		margin-left: auto;
		margin-right: auto;
		a {
			color: #131313;

			&:hover {
				color: var(--product-primary-color);
			}
		}
		.when-closed, .when-opened {
			float: right;
		}
		header {
			background-color: white;
		}
		.card {
			&:first-child {
				border-radius: 3px 3px 0 0;
				border-bottom: none;
			}
			&:last-child {
				border: none;

				.card-header, .collapse {
					border-radius: 0 0 3px 3px;
					border-left: 1px solid #c8ced3;
					border-right: 1px solid #c8ced3;
					border-bottom: 1px solid #c8ced3;
				}
			}
			&:not(:first-child) {
				border-radius: 0;
				border-top: none;
				border-bottom: none;
			}
		}
		.collapse {
			border-bottom: 1px solid #c8ced3;
		}
	}
}
</style>
