<template>
	<div
		class="w-100 landing-page"
		:style="colorsStyles">
		<lazy
			:style="`height: ${introData.height};`"
			render-on-idle>
			<introduction
				:background-style="backgroundStyle"
				:title="introData.title"
				:description="introData.description"
				:product="introData.searchName"
				:video-url="introData.videoUrl"
				:gallery-images="introData.galleryImages"
				:highlight-color="highlightColors.primary"
				:variants-images="introData.variantsImages" />
		</lazy>
		<lazy min-height="300">
			<summary-section
				id="summary-section"
				:image="summaryData.image"
				:header-content="summaryData.headerContent"
				:description="summaryData.description"
				:brochure-url="summaryData.brochureUrl" />
		</lazy>
		<lazy min-height="200">
			<icons-section
				:icons="iconsData.icons"
				:max-cols="iconsData.cols"
				:background-style="`background-color: ${highlightColors.primary}`"
				text-style="color: rgba(255, 255, 255, 0.8)"
				include-addendum
				addendum-class="product-tertiary-color"
				smaller-texts />
		</lazy>
		<lazy min-height="200">
			<accordion
				:items="overviewData" />
		</lazy>
		<lazy min-height="200">
			<horizontal-tabs
				:tabs="tabsData.tabs"
				:disclaimer="tabsData.disclaimer" />
		</lazy>
		<lazy min-height="200">
			<reviews
				:highlight-color="highlightColors.primary"
				:reviews="reviewsData.reviews"
				:video-url="reviewsData.videoUrl"
				:disclaimer="reviewsData.disclaimer"
				:disclaimer2="reviewsData.disclaimer2"
				:text-color="reviewsData.textColor" />
		</lazy>
		<lazy min-height="200">
			<buy-section
				id="buy-section"
				:highlight-color="highlightColors.primary"
				:background="buySectionData.background"
				:title="buySectionData.title"
				:subtitle="buySectionData.subtitle"
				:product="buySectionData.searchName" />
		</lazy>
		<lazy min-height="200">
			<contact-section
				:highlight-color="highlightColors.primary"
				:loading="loadingReplicatedData"
				:email="email"
				:image="contactData.image"
				:title="contactData.title"
				:content="contactData.content"
				:cta="contactData.cta"
				:container-style="['xs', 'sm'].includes(windowWidth) ? '' : 'height: 400px !important;'"
				@mail="openMailer" />
		</lazy>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { PRODUCT_PAGES } from '@/settings/LandingPages';
import { SPECIAL_VALUES } from '@/settings/Replicated';
import { LandingPages, Testimonials } from '@/translations';
import ReplicatedSite from '@/util/ReplicatedSite';
import BuySection from '../BuySection';
import ContactSection from '../ContactSection';
import { RESTRICTED_BY_COUNTRY } from '@/settings/LandingPages2';
import Reviews from '@/views/LandingPages/components/Reviews.vue';
import HorizontalTabs from '@/views/LandingPages/components/ProductLandingPage3/HorizontalTabs.vue';
import Accordion from '@/views/LandingPages/components/ProductLandingPage3/Accordion.vue';
import IconsSection from '@/views/LandingPages/components/IconsSection.vue';
import Lazy from '@/components/Lazy/index.vue';
import SummarySection from '@/views/LandingPages/components/ProductLandingPage2/Summary.vue';
import Introduction from '@/views/LandingPages/components/ProductLandingPage2/Introduction.vue';
import { applyAttrsToHtmlString } from '@/util/HtmlHelper';
import { S3_PATH } from '@/settings/Images';

export default {
	name: 'ProductLandingPage3',
	messages: [LandingPages, Testimonials],
	components: {
		Introduction,
		SummarySection,
		Lazy,
		IconsSection,
		Accordion,
		HorizontalTabs,
		Reviews,
		BuySection,
		ContactSection,
	},
	mixins: [WindowSizes],
	props: {
		pageName: {
			type: String,
			required: true,
		},
	},
	data() {
		return { replicated: new ReplicatedSite() };
	},
	computed: {
		colorsStyles() {
			return {
				'--product-primary-color': this.highlightColors.primary,
				'--product-secondary-color': this.highlightColors.secondary ?? this.highlightColors.primary,
				'--product-tertiary-color': this.highlightColors.tertiary ?? this.highlightColors.primary,
			};
		},
		highlightColors() {
			return PRODUCT_PAGES[this.pageName].colors;
		},
		backgroundHeight() {
			if (this.$replicated.replicatedSponsor()) {
				return this.windowWidth === 'xs' ? 'min-height: 70vh;' : 'min-height: 95vh;';
			}
			return this.windowWidth === 'xs' ? 'min-height: 95vh;' : 'min-height: 100vh;';
		},
		backgroundStyle() {
			const {
				background,
			} = PRODUCT_PAGES[this.pageName].introduction;
			const styles = [
				`background-image: url('${this.requireAsset(background)}');`,
				'background-size: cover;',
				'background-position: 45% 50%;',
				'background-repeat: none;',
				this.backgroundHeight,
			];

			if (this.$replicated.replicatedSponsor()) {
				styles.push(this.windowWidth === 'xs' ? 'margin-top: 120px !important;' : 'margin-top: 35px !important;');
			}

			return styles.join(' ');
		},
		reviewStyles() {
			return {
				b: {
					style: { color: `${this.highlightColors.tertiary}` },
				},
			};
		},
		introData() {
			const {
				description,
				galleryImages,
				searchName,
				title,
				variantsImages,
				videoUrl,
			} = PRODUCT_PAGES[this.pageName].introduction;

			// eslint-disable-next-line no-nested-ternary
			const height = ['xs', 'sm'].includes(this.windowWidth) ? '1200px' : (['md'].includes(this.windowWidth) ? '850px' : '100vh');

			return {
				description: this.translate(description),
				galleryImages: {
					defaultGroup: galleryImages.map((file) => {
						const image = this.requireAsset(file);
						return {
							image,
							thumbnail: image,
						};
					}),
				},
				searchName,
				title: applyAttrsToHtmlString(this.translate(title), {
					h1: { class: 'd-inline section-header product-primary-color' },
				}),
				variantsImages: variantsImages.map((file) => this.requireAsset(file)),
				videoUrl,
				height,
			};
		},
		summaryData() {
			const {
				image,
				title,
				description,
				brochureUrl,
			} = PRODUCT_PAGES[this.pageName].summary;

			return {
				image: this.requireAsset(image),
				headerContent: this.translate(title),
				description: this.translate(description),
				brochureUrl,
			};
		},
		iconsData() {
			const {
				icons,
				iconCols,
			} = PRODUCT_PAGES[this.pageName].summary;

			const result = icons.map((icon) => ({
				text: this.translate(icon.text),
				file: this.requireAsset(icon.file),
			}));

			return {
				cols: iconCols,
				icons: result,
			};
		},
		overviewData() {
			const { overview } = PRODUCT_PAGES[this.pageName];

			return overview.map((faq) => ({
				title: this.translate(faq.title),
				content: this.translate(faq.content, faq.image ? { image: this.requireAsset(faq.image) } : {}),
			}));
		},
		tabsData() {
			const {
				benefits,
				supplementsFacts,
				faqs,
				disclaimer,
			} = PRODUCT_PAGES[this.pageName].tabs;

			return {
				tabs: {
					benefits: {
						content: this.translate(benefits.content),
						image: this.requireAsset(benefits.image),
					},
					supplementsFacts: {
						content: this.translate(supplementsFacts.content),
						images: Object.fromEntries(Object.entries(supplementsFacts.images).map(([key, image]) => [key, this.requireAsset(image)])),
					},
					faqs: faqs.map((faq) => ({ q: this.translate(faq.q), a: this.translate(faq.a) })),
				},
				disclaimer: this.translate(disclaimer),
			};
		},
		reviewsData() {
			const {
				reviews, videoUrl, disclaimer, disclaimer2, textColor,
			} = PRODUCT_PAGES[this.pageName].reviews;

			const result = reviews.map((review) => {
				const {
					image,
					author,
					content,
					stars,
				} = review;

				return {
					image: this.requireAsset(image),
					author,
					content: applyAttrsToHtmlString(`<b>"</b>${this.translate(content)}<b>"</b>`, this.reviewStyles),
					stars,
				};
			});

			return {
				reviews: result,
				videoUrl,
				textColor,
				disclaimer: this.translate(disclaimer),
				disclaimer2: this.translate(disclaimer2),
			};
		},
		buySectionData() {
			const {
				background,
				searchName,
				title,
				subtitle,
			} = PRODUCT_PAGES[this.pageName].buy;

			return {
				background: background.startsWith('#') ? background : this.requireAsset(background),
				searchName,
				title: this.translate(title),
				subtitle: subtitle ? this.translate(subtitle) : '',
			};
		},
		contactData() {
			const {
				image,
				title,
				content,
				cta,
			} = PRODUCT_PAGES[this.pageName].contact;

			const ctaData = this.transformCta(cta);
			return {
				image: this.requireAsset(image),
				title: this.translate(title),
				content: this.translate(content),
				cta: ctaData,
			};
		},
		loadingReplicatedData() {
			return !!this.replicated.data.loading;
		},
		email() {
			try {
				return this.replicated.data.response.data.response.email[0].value;
			} catch (error) {
				return '';
			}
		},
	},
	created() {
		const restricted = RESTRICTED_BY_COUNTRY[this.country] ?? RESTRICTED_BY_COUNTRY.default ?? [];
		if (restricted.includes(this.pageName)) {
			this.$router.replace({ name: 'Store' });
		}

		this.replicated.clear();

		const replicatedUsername = this.$replicated.replicatedId();
		if (replicatedUsername) {
			this.replicated.getReplicatedSiteNav(replicatedUsername).catch(() => {
				this.$replicated.siteOff();
				this.$replicated.siteReplicatedSponsorOff();
				this.$replicated.siteReplicatedPageOff();
			});
		}
	},
	methods: {
		requireAsset(directory) {
			// Allow importing assets with variable directories
			/* eslint-disable global-require */
			/* eslint-disable import/no-dynamic-require */
			// require(`@/assets/images/themes/${process.env.VUE_APP_THEME}/landing_pages/${this.pageName}/${directory}`);
			return `${S3_PATH}/assets/landing-pages/${process.env.VUE_APP_THEME}/${this.pageName}/${directory}`;
		},
		transformCta(cta) {
			const result = {
				text: this.translate(cta.text),
			};

			if (cta.url) {
				result.url = cta.url;
			}

			if (cta.target) {
				result.target = cta.target;
			}
			return result;
		},
		openMailer() {
			if (this.email) {
				location.href = SPECIAL_VALUES.email(this.email); // eslint-disable-line no-restricted-globals
			}
		},
	},
};
</script>
<style lang="scss" scoped>
html, body {
	scroll-behavior: smooth;
}
.landing-page::v-deep {
	a {
		color: var(--product-primary-color);

		&:hover {
			color: var(--product-secondary-color);
			text-decoration: none;
		}
	}
	b, .font-weight-bolder {
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
	}
	li>span, p {
		color: #666;
		line-height: 25px;
		font-weight: 500;
	}
	.border-2 {
		border-width: 2px !important;
	}
	.highlight-block {
		background-color: var(--product-primary-color);
		border-radius: 10px !important;
		line-height: 1em !important;
		font-family: Montserrat, sans-serif !important;
		font-weight: 700;
		padding: 1.5rem 3rem;
		color: white !important;

		& > p {
			color: white !important;
		}
	}
	.product {
		&-primary-color {
			color: var(--product-primary-color) !important;
		}
		&-secondary-color {
			color: var(--product-secondary-color) !important;
		}
		&-tertiary-color {
			color: var(--product-tertiary-color) !important;
		}
	}
	.bg-product {
		&-primary {
			background-color: var(--product-primary-color) !important;
		}
		&-secondary {
			background-color: var(--product-secondary-color) !important;
		}
	}
	.quote {
		box-sizing: border-box;
		font-style: italic;
		padding: 0;
		text-align: center;

		&::before, &::after {
			color: var(--product-primary-color);
			display: block;
			font-family: MontserratExtraBold, Montserrat, sans-serif !important;
			font-size: 20px;
		}
		&::before {
			content: "“\A";
			white-space: pre;
		}
		&::after {
			content: "\A”";
			line-height: 0.75em;
			white-space: pre;
		}
	}
	.secondary-header {
		font-family: Montserrat, sans-serif !important;
		font-size: 2rem;
		font-weight: 500;
		letter-spacing: 4px;
		text-transform: uppercase;
	}
	.section-header {
		font-size: 3rem;
		font-family: MontserratExtraBold, Montserrat, sans-serif !important;
		font-weight: 700 !important;
	}
	@media only screen and (max-width: 767px) {
		h1.section-header {
			font-size: 2.5rem !important;
		}
		h1 + .secondary-header {
			font-size: 1.75rem !important;
		}
	}
	.source {
		color: var(--product-secondary-color) !important;
		font-size: 0.875em;
		font-style: italic;
	}
}
</style>
